import React, { useEffect, useState } from 'react';
import {
  getNewWarehouseDefaults,
  createNewResource, updateWarehouse,
} from './SandboxApi';

function CreateUpdateWarehouseModal({
  isOpen, onClose, accessToken, apiKey, handleCreateNewResource, existingWarehouse,
  handleCreateUpdateError,
}) {
  const [warehouseData, setWarehouseData] = useState(getNewWarehouseDefaults());
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setWarehouseData(getNewWarehouseDefaults());
    setLoading(false);
    onClose();
  };

  const handleCreateUpdate = (event) => {
    event.preventDefault();
    setLoading(true);
    if (existingWarehouse) {
      updateWarehouse(accessToken, apiKey, existingWarehouse.id, warehouseData)
        .then((response) => {
          handleCreateNewResource(response);
          handleClose();
        });
    } else {
      createNewResource(accessToken, apiKey, 'warehouses', warehouseData).then((response) => {
        handleCreateNewResource(response);
        handleClose();
      }).catch((error) => {
        handleCreateUpdateError(error);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (existingWarehouse) {
      setWarehouseData(existingWarehouse);
    } else {
      setWarehouseData(getNewWarehouseDefaults());
    }
  }, [existingWarehouse]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-gray-800 opacity-50" onClick={() => handleClose()} tabIndex={-1} aria-hidden="true" />
      <form className="flex flex-col bg-white rounded-lg p-6 relative z-10 h-3/4" onSubmit={handleCreateUpdate}>
        <h2 className="text-2xl font-semibold pt-1 pb-0">
          {existingWarehouse ? 'Update' : 'Create New'}
          {' '}
          Warehouse
        </h2>
        <div className="grid grid-cols-3 gap-2" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
          <div key="name">
            <span htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </span>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={warehouseData.name}
              onChange={(e) => setWarehouseData({ ...warehouseData, name: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="code">
            <span htmlFor="code" className="block text-sm font-medium text-gray-700">Code</span>
            <input
              id="code"
              name="code"
              required
              value={warehouseData.code}
              onChange={(e) => setWarehouseData({ ...warehouseData, code: e.target.value })}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <hr className="col-span-3" style={{ borderTop: '1px solid #bbb', marginTop: '12px' }} />
          <span className="col-span-3 block text-sm font-medium text-gray-700">Warehouse Address</span>
          <div key="address1">
            <span htmlFor="address1" className="block text-sm font-medium text-gray-700">Address 1</span>
            <input
              type="text"
              id="address1"
              name="address1"
              value={warehouseData.address.address1}
              onChange={(e) => {
                setWarehouseData({
                  ...warehouseData,
                  address: { ...warehouseData.address, address1: e.target.value },
                });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="adderss2">
            <span htmlFor="address2" className="block text-sm font-medium text-gray-700">Address 2</span>
            <input
              type="text"
              id="address2"
              name="address2"
              value={warehouseData.address.address2}
              onChange={(e) => {
                setWarehouseData({
                  ...warehouseData,
                  address: { ...warehouseData.address, address2: e.target.value },
                });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="city">
            <span htmlFor="city" className="block text-sm font-medium text-gray-700">City</span>
            <input
              type="text"
              id="city"
              name="city"
              value={warehouseData.address.city}
              onChange={(e) => {
                setWarehouseData({
                  ...warehouseData,
                  address: { ...warehouseData.address, city: e.target.value },
                });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="state">
            <span htmlFor="state" className="block text-sm font-medium text-gray-700">State</span>
            <input
              type="text"
              id="state"
              name="state"
              value={warehouseData.address.state}
              onChange={(e) => {
                setWarehouseData({
                  ...warehouseData,
                  address: { ...warehouseData.address, state: e.target.value },
                });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="postalCode">
            <span htmlFor="postalCode" className="block text-sm font-medium text-gray-700">Postal Code</span>
            <input
              type="text"
              id="postalCode"
              name="postalCode"
              value={warehouseData.address.postal_code}
              onChange={(e) => {
                setWarehouseData({
                  ...warehouseData,
                  address: { ...warehouseData.address, postal_code: e.target.value },
                });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
          <div key="country">
            <span htmlFor="country" className="block text-sm font-medium text-gray-700">Country</span>
            <input
              type="text"
              id="country"
              name="country"
              value={warehouseData.address.country}
              onChange={(e) => {
                setWarehouseData({
                  ...warehouseData,
                  addEventListener: { ...warehouseData.address, country: e.target.value },
                });
              }}
              className="border border-gray-300 rounded-md p-2"
              size="small"
            />
          </div>
        </div>
        <div className="flex flex-grow justify-end pt-4">
          <button
            type="button"
            onClick={() => onClose()}
            className="self-end bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded-lg mr-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="self-end bg-trackstarAllDark hover:bg-trackstarAllLight text-white font-semibold py-2 px-4 rounded-lg"
            disabled={loading}
          >
            {existingWarehouse ? 'Update' : 'Create'}
          </button>
        </div>
      </form>
    </div>
  );
}
export default CreateUpdateWarehouseModal;
