import React, { useState } from 'react';
import {
  UserButton, SignOutButton, useUser, OrganizationSwitcher,
} from '@clerk/clerk-react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';

function Navbar(props) {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(location.pathname.split('/')[1]);
  const { numIssues } = props;
  const { user } = useUser();
  const showSwitcher = user.organizationMemberships.length > 1;
  return (
    <Sidebar className="h-screen border-r p-2" backgroundColor="white" width="220px">
      <Menu>
        <MenuItem
          icon={<img src="/ts_logo_2024.png" alt="Trackstar Logo" className="h-8 w-8 rounded-lg" />}
          component={<Link to="/" />}
          className="text-l font-semibold text-sm"
          onClick={() => setActiveMenu('')}
        >
          Trackstar
        </MenuItem>
      </Menu>
      <Menu>
        <MenuItem
          icon={<img src="/home.png" alt="home" className="h-5 w-5 " />}
          component={<Link to="/" />}
          className={`text-gray-600 font-semibold text-sm ${activeMenu === '' ? 'bg-gray-100' : 'bg-white'}`}
          onClick={() => setActiveMenu('')}
        >
          Home
        </MenuItem>
        <MenuItem
          icon={<img src="/connections.png" alt="connections" className="h-5 w-5" />}
          component={<Link to="/connections" />}
          onClick={() => setActiveMenu('connections')}
          className={`${activeMenu === 'connections' ? 'bg-gray-100' : 'bg-white'}`}
        >
          <div className="flex justify-between items-center">
            <p className="text-gray-600 font-semibold text-sm">Connections</p>
            {numIssues && numIssues > 0 ? <span className="bg-trackstarBadLight rounded-lg px-1 text-trackstarBadDark text-sm">{numIssues}</span> : ''}
          </div>
        </MenuItem>
        <MenuItem
          icon={<img src="/webhooks.png" alt="webhooks" className="h-5 w-5" />}
          component={<Link to="/webhooks" />}
          className={`text-gray-600 font-semibold text-sm ${activeMenu === 'webhooks' ? 'bg-gray-100' : 'bg-white'}`}
          onClick={() => setActiveMenu('webhooks')}
        >
          Webhooks
        </MenuItem>
        <MenuItem
          icon={<img src="/logs.png" alt="sandbox" className="h-5 w-5" />}
          component={<Link to="/api-log" />}
          className={`text-gray-600 font-semibold text-sm ${activeMenu === 'api-log' ? 'bg-gray-100' : 'bg-white'}`}
          onClick={() => setActiveMenu('api-log')}
        >
          API Log
        </MenuItem>
        <MenuItem
          icon={<img src="/hourglass.png" alt="sandboix" className="h-5 w-5" />}
          component={<Link to="/sandbox" />}
          className={`text-gray-600 font-semibold text-sm ${activeMenu === 'sandbox' ? 'bg-gray-100' : 'bg-white'}`}
          onClick={() => setActiveMenu('sandbox')}
        >
          Sandbox
        </MenuItem>
      </Menu>
      <hr className="border-gray-300 my-4 absolute bottom-10 w-64" />
      <Menu
        className="absolute bottom-0 w-full"
      >
        <MenuItem
          icon={<img src="/puzzle-piece-01.png" alt="integrations" className="h-5 w-5" />}
          component={<Link to="/integrations" />}
          className={`text-gray-600 font-semibold text-sm ${activeMenu === 'integrations' ? 'bg-gray-100' : 'bg-white'}`}
          onClick={() => setActiveMenu('integrations')}
        >
          Integrations
        </MenuItem>

        <MenuItem
          icon={<img src="/settings.png" alt="settings" className="h-5 w-5" />}
          component={<Link to="/settings" />}
          className={`text-gray-600 font-semibold text-sm ${activeMenu === 'settings' ? 'bg-gray-100' : 'bg-white'}`}
          onClick={() => setActiveMenu('settings')}

        >
          Settings
        </MenuItem>

        {showSwitcher ? (
          <div className="p-5">
            <OrganizationSwitcher
              hidePersonal
              appearance={{
                elements: {
                  organizationSwitcherPopoverActionButton__createOrganization: {
                    display: 'none',
                  },
                  organizationSwitcherPopoverActionButton__manageOrganization: {
                    display: 'none',
                  },
                },
              }}
            />
          </div>
        ) : ''}
        <MenuItem>
          <div className="flex gap-4">
            <UserButton />
            <SignOutButton className="text-gray-600 font-semibold text-sm" />
          </div>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

export default Navbar;
