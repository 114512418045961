import React, { useEffect, useState } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function Integrations() {
  const [pathName, setPathName] = useState(null);
  const [integrationListLoading, setIntegrationListLoading] = useState(true);
  const [integrationList, setIntegrationList] = useState([]);
  const [selectedIntegrationList, setSelectedIntegrationList] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('integrations')) {
      const temp = location.pathname.split('/').pop();
      setPathName(temp);
    }
  }, [location]);
  const getIntegrationsPlaceholder = () => {
    let placeholder = '';
    if (integrationListLoading) {
      placeholder = 'Loading...';
    } else if (selectedIntegrationList.length === 0) {
      placeholder = 'Select Integrations...';
    }
    return placeholder;
  };

  return (
    <div className="flex flex-col gap-4 p-5 w-10/12 h-screen overflow-y-auto">
      <h1 className="text-3xl font-semibold leading-9 mb-4">
        Integrations
      </h1>
      <div className="flex flex-row justify-between items-end">
        <div className="flex flex-row gap-6">
          <Link to="actions" className={`text-md font-medium ${pathName === 'actions' ? 'text-black' : 'text-gray-600'}`}>Actions</Link>
        </div>
        {pathName === 'actions' && (
        <Autocomplete
          limitTags={2}
          multiple
          options={integrationList}
          value={selectedIntegrationList}
          onChange={(_, newValue) => setSelectedIntegrationList(newValue)}
          renderInput={(params) => (
            <TextField
              id={params.id}
              fullWidth={params.fullWidth}
              disabled={params.disabled}
              size={params.size}
              inputProps={params.inputProps}
                /* eslint-disable-next-line react/jsx-no-duplicate-props */
              InputProps={params.InputProps}
              variant="outlined"
              placeholder={getIntegrationsPlaceholder()}
            />
          )}
          disabled={integrationListLoading}
          size="small"
          sx={{ width: 300, maxLines: 1 }}
        />
        )}
      </div>
      <div className="flex flex-col h-full max-h-full overflow-auto">
        <Outlet
          context={[selectedIntegrationList, setIntegrationList, setIntegrationListLoading]}
        />
      </div>

    </div>
  );
}

export default Integrations;
