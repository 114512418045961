import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import {
  DataGridPro,
} from '@mui/x-data-grid-pro';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useFetch } from './api';

function Actions() {
  const [endpointList, setEndpointList] = useState([]);
  const [endpointListLoading, setEndpointListLoading] = useState(false);
  const [integrationInfo, setIntegrationInfo] = useState([]);
  const [integrationInfoLoading, setIntegrationInfoLoading] = useState(false);
  const [actionRows, setActionRows] = useState([]);
  const [colHeaders, setColHeaders] = useState([]);
  const [selectedIntegrationList, setIntegrationList,
    setIntegrationListLoading] = useOutletContext();
  const [colVisibilityModel, setColVisibilityModel] = useState({});
  const trackstar = useFetch();

  useEffect(() => {
    setEndpointListLoading(true);
    trackstar('internal/available-actions').then((data) => {
      const modifiedActions = data.actions.filter((action) => !['create_inventory_item', 'update_inventory_item'].includes(action));
      setEndpointList(modifiedActions);
      setEndpointListLoading(false);
    });
    setIntegrationInfoLoading(true);
    trackstar('internal/wms/integrations').then((data) => {
      const sortedIntegrations = data.data.sort(
        (a, b) => a.integration_name.localeCompare(b.integration_name),
      );
      //   format integration name to be more readable
      const adjSortedIntegrations = sortedIntegrations.map((integration) => {
        const formattedIntegration = integration;
        formattedIntegration.integration_name = integration.integration_name.split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return formattedIntegration;
      });
      setIntegrationInfo(adjSortedIntegrations);
      setIntegrationInfoLoading(false);
      const integrationList = adjSortedIntegrations.map(
        (integration) => integration.integration_name,
      );
      setIntegrationList(integrationList);
      setIntegrationListLoading(false);
    });
  }, []);

  useEffect(() => {
    if (endpointList && integrationInfo) {
      const rows = [];
      const headers = [{
        field: 'action',
        headerName: 'Action',
        width: 215,
        headerAlign: 'left',
        sortable: false,
        renderCell: (params) => {
          const { value } = params;
          // replace underscores with spaces and capitalize first letter of each word
          return value.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        },
      },
      ];
      integrationInfo.forEach((integration) => {
        if (integration.integration_name === 'sandbox') {
          return;
        }
        headers.push({
          field: integration.integration_name,
          headerName: integration.integration_name,
          width: 175,
          sortable: false,
          headerAlign: 'center',
          renderCell: (params) => {
            const { value } = params;
            return value === 'Yes' && <CheckCircleIcon color="success" />;
          },
          renderHeader: (params) => {
            const currIntegrationName = params.field;
            const formattedIntegrationName = integration.integration_name;
            return (
              <div className="flex gap-2 items-center">
                <img src={integration.logo_url} alt={`${currIntegrationName} logo`} className="h-5 w-5" />
                <p>{formattedIntegrationName}</p>
              </div>
            );
          },
        });
      });
      endpointList.forEach((endpoint, index) => {
        const row = { action: endpoint, id: index };
        integrationInfo.forEach((integration) => {
          row[integration.integration_name] = integration.available_actions.includes(endpoint) ? 'Yes' : 'No';
        });
        rows.push(row);
      });
      setActionRows(rows);
      setColHeaders(headers);
    }
  }, [endpointList, integrationInfo]);

  useEffect(() => {
    if (selectedIntegrationList.length > 0) {
    //   add hide: true to columns that are not in selectedIntegrationList
      const modifiedColVisibilityModel = {};
      colHeaders.forEach((header) => {
        if (header.field !== 'action') {
          if (!selectedIntegrationList.includes(header.field)) {
            modifiedColVisibilityModel[header.field] = false;
          }
        }
      });
      setColVisibilityModel(modifiedColVisibilityModel);
    } else {
      setColVisibilityModel({});
    }
  }, [selectedIntegrationList]);

  return (
    <DataGridPro
      rows={actionRows}
      columns={colHeaders}
      loading={endpointListLoading || integrationInfoLoading}
      hideFooter
      initialState={{ pinnedColumns: { left: ['action'] } }}
      showCellVerticalBorder
      disableColumnReorder
      disableColumnMenu
      columnVisibilityModel={colVisibilityModel}
      sx={{
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: '#F5F5F5',
          borderRadius: '0px !important',
          fontFamily: 'ui-sans-serif, system-ui, sans-serif',
          fontWeight: '400',
          fontSize: '13px',
          color: '#6b7280',
        },
        '& .column-header-no-border .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        borderRadius: '0px !important',
        '& .MuiDataGrid-cell': {
          fontSize: '13px',
          fontWeight: '400',
          fontFamily: 'ui-sans-serif, system-ui, sans-serif',
          display: 'flex',
          justifyContent: 'center',
        },
        '& .MuiDataGrid-pinnedColumns .MuiDataGrid-cell': {
          fontSize: '13px',
          fontWeight: '400',
          fontFamily: 'ui-sans-serif, system-ui, sans-serif',
          display: 'flex',
          justifyContent: 'left',
        },
      }}
    />
  );
}

export default Actions;
