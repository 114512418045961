const ApiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const formatDateForInput = (date) => {
  if (!date) {
    return '';
  }
  return date.slice(0, 16);
};
export const formatDateForApi = (date) => {
  const newDate = `${date}:00.000Z`;
  return newDate;
};

export const replaceEmptyStringsWithNull = (object) => {
  Object.keys(object).forEach((key) => {
    // Check if the current value is an object (nested object)
    if (typeof object[key] === 'object' && object[key] !== null) {
      // Recursively call the function for nested objects
      replaceEmptyStringsWithNull(object[key]);
    } else if (object[key] === '') {
      // If the value is an empty string, replace it with null
      // eslint-disable-next-line no-param-reassign
      object[key] = null;
    }
  });
};

export const getResources = (accessToken, apiKey, resourceType) => {
  const resources = fetch(`${ApiEndpoint}/wms/${resourceType}`, {
    method: 'GET',
    headers: {
      'x-trackstar-api-key': apiKey,
      'x-trackstar-access-token': accessToken,
      'Content-Type': 'application/json',
      'Response-Type': 'json',

    },
  }).then((response) => response.json())
    .then((data) => data.data)
    .catch((error) => {
      console.error('Error:', error);
    });

  return resources;
};

export const getMeta = (apiKey, method, resource) => {
  const meta = fetch(`${ApiEndpoint}/wms/${resource}/meta/sandbox?method=${method}`, {
    method: 'GET',
    headers: {
      'x-trackstar-api-key': apiKey,
      'Content-Type': 'application/json',
      'Response-Type': 'json',
    },
  }).then((response) => response.json())
    .then((data) => data.definitions)
    .catch((error) => {
      console.error('Error:', error);
    });
  return meta;
};

export const getNewOrderDefaults = () => {
  const oneWeekFromNow = new Date();
  oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
  const randomOrderNumber = Math.random().toString(36).substring(7);
  const randomReferenceId = Math.random().toString(36).substring(7);

  return {
    channel: 'shopify',
    invoice_currency_code: 'USD',
    line_items: [{
      product_id: 'product_id_0', quantity: 1, sku: 'sku', unit_price: 10.0, discount_amount: 0.0,
    }],
    order_number: randomOrderNumber,
    raw_status: 'open',
    reference_id: randomReferenceId,
    ship_to_address: {
      address1: '123 Main St', address2: 'Apt 4', city: 'San Francisco', company: 'Company1', country: 'US', full_name: 'Jane Doe', postal_code: '94105', state: 'CA',
    },
    shipping_method: '2 Day Shipping',
    status: 'open',
    total_discount: 0.0,
    total_price: 10.0,
    total_shipping: 0.0,
    total_tax: 0.0,
  };
};

export const createNewResource = (accessToken, apiKey, resourceType, resourceData = {}) => {
  replaceEmptyStringsWithNull(resourceData);
  const resourceCreated = fetch(`${ApiEndpoint}/wms/${resourceType}`, {
    method: 'POST',
    headers: {
      'x-trackstar-api-key': apiKey,
      'x-trackstar-access-token': accessToken,
      'Content-Type': 'application/json',
      'Response-Type': 'json',
    },
    body: JSON.stringify(resourceData),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        return Promise.reject(data.error);
      }
      return data.data;
    });
  return resourceCreated;
};

export const updateOrder = (accessToken, apiKey, orderId, orderData) => {
  const orderDataCopy = { ...orderData };
  delete orderDataCopy.id;
  delete orderDataCopy.shipments;
  delete orderDataCopy.trading_partner;
  replaceEmptyStringsWithNull(orderDataCopy);
  const orderUpdated = fetch(`${ApiEndpoint}/wms/orders/${orderId}`, {
    method: 'PUT',
    headers: {
      'x-trackstar-api-key': apiKey,
      'x-trackstar-access-token': accessToken,
      'Content-Type': 'application/json',
      'Response-Type': 'json',
    },
    body: JSON.stringify(orderDataCopy),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        return Promise.reject(data.error);
      }
      return data.data;
    });
  return orderUpdated;
};

export const fulfillOrder = (accessToken, apiKey, orderId) => {
  const fulfilledOrder = fetch(`${ApiEndpoint}/sandbox/simulate/orders/${orderId}/fulfill`, {
    method: 'PUT',
    headers: {
      'x-trackstar-api-key': apiKey,
      'x-trackstar-access-token': accessToken,
      'Content-Type': 'application/json',
      'Response-Type': 'json',
    },
  })
    .then((response) => response.json())
    .then((data) => data.data)
    .catch((error) => {
      console.error('Error:', error);
    });
  return fulfilledOrder;
};

export const cancelOrder = (accessToken, apiKey, orderId) => {
  const cancelledOrder = fetch(`${ApiEndpoint}/wms/orders/${orderId}/cancel`, {
    method: 'PUT',
    headers: {
      'x-trackstar-api-key': apiKey,
      'x-trackstar-access-token': accessToken,
      'Content-Type': 'application/json',
      'Response-Type': 'json',
    },
  })
    .then((response) => response.json())
    .then((data) => data.data)
    .catch((error) => {
      console.error('Error:', error);
    });
  return cancelledOrder;
};

export const getNewInboundDefaults = () => {
  const oneWeekFromNow = new Date();
  oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
  const oneWeekFromNowIso = oneWeekFromNow.toISOString();
  const randomOrderNumber = Math.random().toString(36).substring(7);

  return {
    supplier: 'supplier_A',
    line_items: [{
      expected_quantity: 1, sku: 'sku',
    }],
    purchase_order_number: randomOrderNumber,
    expected_arrival_date: oneWeekFromNowIso,
    warehouse_id: 'warehouse_id',
    tracking_number: 'tracking_number_0',
  };
};

export const updateInboundShipment = (
  accessToken,
  apiKey,
  inboundShipmentId,
  inboundShipmentData,
) => {
  const inboundShipmentDataCopy = { ...inboundShipmentData };
  delete inboundShipmentDataCopy.id;
  delete inboundShipmentDataCopy.receipts;
  replaceEmptyStringsWithNull(inboundShipmentDataCopy);
  return fetch(`${ApiEndpoint}/wms/inbound-shipments/${inboundShipmentId}`, {
    method: 'PUT',
    headers: {
      'x-trackstar-api-key': apiKey,
      'x-trackstar-access-token': accessToken,
      'Content-Type': 'application/json',
      'Response-Type': 'json',
    },
    body: JSON.stringify(inboundShipmentDataCopy),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        return Promise.reject(data.error);
      }
      return data.data;
    });
};

export const getNewReturnDefaults = () => {
  const oneWeekFromNow = new Date();
  oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
  const randomOrderId = Math.random().toString(36).substring(7);

  return {
    line_items: [{
      sku: 'sku', quantity: 1,
    }],
    order_id: randomOrderId,
    warehouse_id: 'warehouse_id',
  };
};

export const updateReturn = (
  accessToken,
  apiKey,
  returnId,
  returnData,
) => {
  const returnDataCopy = { ...returnData };
  delete returnDataCopy.id;
  replaceEmptyStringsWithNull(returnDataCopy);
  return fetch(`${ApiEndpoint}/wms/returns/${returnId}`, {
    method: 'PUT',
    headers: {
      'x-trackstar-api-key': apiKey,
      'x-trackstar-access-token': accessToken,
      'Content-Type': 'application/json',
      'Response-Type': 'json',
    },
    body: JSON.stringify(returnDataCopy),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        return Promise.reject(data.error);
      }
      return data.data;
    });
};
export const getNewProductDefaults = () => {
  const currentDateIso = new Date().toISOString();
  const randomExternalProductId = Math.random().toString(36).substring(7);
  const randomGtin = Math.random().toString(36).substring(7);

  return {
    created_date: currentDateIso,
    name: 'name',
    sku: 'sku',
    gtin: randomGtin,
    is_kit: false,
    active: true,
    supplier: 'supplier_id',
    country_of_origin: 'US',
    harmonized_code: 'harmonized_code',
    supplier_products: [
      {
        supplier_id: 'supplier_id',
        supplier_name: 'supplier_name',
        external_id: randomExternalProductId,
        unit_cost: '1',
      },
    ],
    updated_date: currentDateIso,
    warehouse_customer_id: 'warehouse_customer_id',
  };
};

export const updateProduct = (
  accessToken,
  apiKey,
  productId,
  productData,
) => {
  const productDataCopy = { ...productData };
  delete productDataCopy.id;
  delete productDataCopy.inventory_items;
  replaceEmptyStringsWithNull(productDataCopy);
  return fetch(`${ApiEndpoint}/wms/products/${productId}`, {
    method: 'PUT',
    headers: {
      'x-trackstar-api-key': apiKey,
      'x-trackstar-access-token': accessToken,
      'Content-Type': 'application/json',
      'Response-Type': 'json',
    },
    body: JSON.stringify(productDataCopy),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        return Promise.reject(data.error);
      }
      return data.data;
    });
};

export const getNewInventoryDefaults = () => {
  const currentDateIso = new Date().toISOString();

  return {
    created_date: currentDateIso,
    name: 'name',
    sku: 'sku',
    active: true,
    awaiting: 0,
    onhand: 10,
    committed: 0,
    unfulfillable: 0,
    fulfillable: 10,
    unsellable: 0,
    sellable: 10,
    measurements: {
      length: 1.0,
      width: 1.0,
      height: 1.0,
      weight: 1.0,
      unit: 'in',
      weight_unit: 'lb',
    },
    updated_date: currentDateIso,
    warehouse_customer_id: 'warehouse_customer_id',
  };
};

export const updateInventoryItem = (
  accessToken,
  apiKey,
  inventoryItemId,
  inventoryData,
) => {
  const inventoryDataCopy = { ...inventoryData };
  delete inventoryDataCopy.id;
  replaceEmptyStringsWithNull(inventoryDataCopy);
  return fetch(`${ApiEndpoint}/wms/inventory/${inventoryItemId}`, {
    method: 'PUT',
    headers: {
      'x-trackstar-api-key': apiKey,
      'x-trackstar-access-token': accessToken,
      'Content-Type': 'application/json',
      'Response-Type': 'json',
    },
    body: JSON.stringify(inventoryDataCopy),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        return Promise.reject(data.error);
      }
      return data.data;
    });
};

export const getNewWarehouseDefaults = () => {
  const currentDateIso = new Date().toISOString();

  return {
    created_date: currentDateIso,
    name: 'name',
    code: 'code',
    address: {
      address1: '123 Main St', address2: 'Fl 1', address3: '', city: 'San Francisco', country: 'US', postal_code: '94105', state: 'CA',
    },
    updated_date: currentDateIso,
  };
};

export const updateWarehouse = (
  accessToken,
  apiKey,
  warehouseId,
  warehouseData,
) => {
  const warehouseDataCopy = { ...warehouseData };
  delete warehouseDataCopy.id;
  replaceEmptyStringsWithNull(warehouseDataCopy);
  return fetch(`${ApiEndpoint}/wms/warehouses/${warehouseId}`, {
    method: 'PUT',
    headers: {
      'x-trackstar-api-key': apiKey,
      'x-trackstar-access-token': accessToken,
      'Content-Type': 'application/json',
      'Response-Type': 'json',
    },
    body: JSON.stringify(warehouseDataCopy),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        return Promise.reject(data.error);
      }
      return data.data;
    });
};
